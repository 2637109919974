import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import {
  NotificationTrigger,
  NotificationsOptions,
  RequestStatus,
  TypeNotification,
} from '#/types/enums';
import { Store } from '#/store/defaultState';
import authHelper from './auth-helper';
import { constructKycData, initialStateUser } from './helpers';
import {
  SubscriberUserEvent,
  UserEventSubscribers,
  User,
  UserInfo,
  UserMarketCurrencies,
  UserLanguages,
  IsActive
} from './types';
import { getTimeZone } from '#/util';

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {
    updateUser(state, action: PayloadAction<UserInfo>) {
      state.userInfo = {
        ...action.payload,
        language: action.payload.language || UserLanguages.English,
        primary_market_currency: action.payload.primary_market_currency || UserMarketCurrencies.USDT,
        timezone: action.payload.timezone || getTimeZone(),
        userKycInfo: constructKycData(action.payload.kyc_data),
      }
    },
    updateVerifyTwoFa(state, action: PayloadAction<RequestStatus | 1>) {
      state.verifyTwoFaRequest = action.payload;
    },
    setTwoFaVefiryMessage(state, action: PayloadAction<string>) {
      state.twoFaVerifyMessage = action.payload;
    },
    uploadDocumentStatus(state, action: PayloadAction<RequestStatus>) {
      state.uploadDocumentStatus = action.payload;
    },
    updateIsInactive(state, action: PayloadAction<boolean>) {
      state.isInactive = action.payload;
    },
    updateEmail(state, action: PayloadAction<string>) {
      state.userInfo.email = action.payload;
    },
    updateAuthStatus(state, action: PayloadAction<RequestStatus>) {
      state.authStatus = action.payload;
    },
    getUserStatus(state, action: PayloadAction<RequestStatus>) {
      state.getUserStatus = action.payload;
    },
    updateUserStatus(state, action: PayloadAction<RequestStatus>) {
      state.updateUserStatus = action.payload;
    },
    updateAuth(
        state,
        action: PayloadAction<{
          token: string,
          expires_at: number | string,
          authStatus: RequestStatus,
          refreshToken?: string;
        }>
      ) {
      state.accessToken = action.payload.token;
      state.tokenExpiresAt = String(action.payload.expires_at);
      state.authStatus = action.payload.authStatus;
      authHelper.setAuthToStorage(action.payload);
      state.refreshToken = action.payload.refreshToken ?? '';
    },
    setTwoFa(state, action: PayloadAction<string>) {
      state.twoFa = action.payload;
    },
    subscribeToUserEvent(state, action: PayloadAction<SubscriberUserEvent>) {
      state.userEventSubscribers[action.payload.id] = action.payload;
    },
    unsubscribeUserEvent(state, action: PayloadAction<string>) {
      state.userEventSubscribers[action.payload] = undefined as unknown as SubscriberUserEvent;
    },
    updatePermissions(state, action: PayloadAction<Array<string>>) {
      state.permissions = action.payload;
    },
    setUserCountry(state, action: PayloadAction<string>) {
      state.userInfo.userCounrty = action.payload;
    },
    updateTokenRefreshing(state, action: PayloadAction<boolean>) {
      state.isTokenRefreshing = action.payload;
    },
    updateChangePasswordStatus(state, action: PayloadAction<RequestStatus>) {
      state.changePasswordStatus = action.payload;
    },
    updateChangePasswordError(state, action: PayloadAction<string>) {
      state.changePasswordError = action.payload;
    },
    updateAntiPhishingStatus(state, action: PayloadAction<RequestStatus>) {
      state.updateAntiPhishingStatus = action.payload;
    },
  },
});

export const {
  updateUser,
  updateAuth,
  updateUserStatus,
  getUserStatus,
  updateEmail,
  updateAuthStatus,
  uploadDocumentStatus,
  subscribeToUserEvent,
  unsubscribeUserEvent,
  updatePermissions,
  updateVerifyTwoFa,
  setTwoFa,
  updateIsInactive,
  setUserCountry,
  updateTokenRefreshing,
  updateChangePasswordStatus,
  updateChangePasswordError,
  setTwoFaVefiryMessage,
  updateAntiPhishingStatus,
} = userSlice.actions;

export default userSlice.reducer;

export const selectUserState = (state: Store): User => state.user;

export const _selectSubscribers: (state: Store) => UserEventSubscribers = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userEventSubscribers || {},
);

export const _selectSubscribersArray: (state: Store) => Array<SubscriberUserEvent> = createDraftSafeSelector(
  _selectSubscribers,
  (userEventSubscribers: UserEventSubscribers) => Object.values(userEventSubscribers || {}).filter((_) => !!_),
);

export const selectTwoFa: (state: Store) => string = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.twoFa,
);

export const selectVerifyTwoFaRequest: (state: Store) => RequestStatus | 1 = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.verifyTwoFaRequest,
);

export const selectTwoFaVefiryMessage: (state: Store) => string = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.twoFaVerifyMessage,
);

export const selectUserIsInactive: (state: Store) => boolean = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.isInactive,
);

export const selectUser: (state: Store) => UserInfo = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo,
);

export const selectCryptoPayEnabled: (state: Store) => IsActive = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.crypto_pay,
);

export const selectPermissions: (state: Store) => Array<string> = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.permissions,
);

export const selectGetUserStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.getUserStatus,
);

export const selectUploadDocumentStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.uploadDocumentStatus,
);

export const selectUserUpdateStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.updateUserStatus,
);

export const selectUserEmail: (state: Store) => string = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.email,
);

export const selectUserMarketCurrency: (state: Store) => UserMarketCurrencies = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.primary_market_currency,
);

export const selectUserMarketCurrencyIsUsd: (state: Store) => boolean = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.primary_market_currency === UserMarketCurrencies.USD,
);

export const selectUserProfilePic: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.profile_pic_url,
);

export const selectUserID: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.user_id,
);

export const selectAuthStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.authStatus,
);

export const selectIsAuth: (state: Store) => boolean = createDraftSafeSelector(
  selectAuthStatus,
  (authStatus: RequestStatus) => authStatus === RequestStatus.Success,
);

export const selectAccessToken: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.accessToken,
);

export const selectRefreshToken: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.refreshToken,
);

export const selectTokenExpires: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.tokenExpiresAt,
);

export const selectFavoriteInstrumentsIds: (state: Store) => Array<string> = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.favorite_instruments,
);

export const selectUserOptionNotifications: (state: Store) => NotificationsOptions | {} = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.notifications_options,
);

export const selectUserClientNotifications: (state: Store) => NotificationTrigger[] = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.notifications_options[TypeNotification.Client.toLowerCase()],
);

export const selectUserCountry: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.userCounrty,
);

export const selectUserFeeGroup: (state: Store) => string | undefined = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.fee_group_id,
);

export const selectTokenRefreshing: (state: Store) => boolean = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.isTokenRefreshing,
);

export const selectChangePasswordStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.changePasswordStatus,
);

export const selectChangePasswordError: (state: Store) => string = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.changePasswordError,
);

export const selectUpdateAntiPhishingStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.updateAntiPhishingStatus,
);