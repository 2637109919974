import langConfig, { Languages } from '../language/langConfig';

const poeditorToken: string = '4f54c5596daaee53e2967303c2b52cf3'; // admin: Alex Paiziak
const poeditorUrl: string = 'https://api.poeditor.com/v2';

export enum PoeditorProjects {
  Cointrader = 554781,
  Coinbar = 658879,
  // add client project id from poeditor or create project from PoeditorService to get it
}

export interface PoeditorConfig {
  projectId: PoeditorProjects,
  baseProjectId: PoeditorProjects,
  languages: Array<Languages | string>,
  serviceConfig: { token: string, url: string },
  additionalProjects?: Array<number>,
}

const poeditorConfig: PoeditorConfig = {
  projectId: PoeditorProjects.Coinbar, // Clients poeditor project ID
  baseProjectId: PoeditorProjects.Cointrader, // WARNING: do not change base project
  serviceConfig: {
    token: poeditorToken,
    url: poeditorUrl,
  },  
  languages: Object.keys(langConfig.items || { en: '' }),
  additionalProjects: [], // TODO maybe later add additional project
};

export default poeditorConfig;