import { FavoriteAddressCrypto, FavoriteFiatDestination } from '#reducers/trade/interfaces';
import { IAddressBookState } from './types';

export const defaultCryptoAddress: FavoriteAddressCrypto = {
  address: '',
  currency_id: '',
  address_tag_type: undefined,
  address_tag_value: undefined,
  name: '',
  network: undefined,
};

export const defaultFiatAddress: FavoriteFiatDestination = {
  bank_address: '',
  bank_bic: '',
  bank_name: '',
  beneficiary_account_number: '',
  beneficiary_address_line_1: null,
  beneficiary_address_line_2: null,
  beneficiary_name: '',
  name: '',
  notes: null,
  reference: null,
  routing_number: null,
  bank_country: '',
  bank_region: '',
  bank_city: '',
  bank_postal_code: '',
  beneficiary_country: '',
  beneficiary_region: '',
  beneficiary_city: '',
  beneficiary_postal_code: '',
  bank_iban: '',
}

export const initialAddressBookState: IAddressBookState = {
  currentAddressCrypto: defaultCryptoAddress,
  currentAddressFiat: defaultFiatAddress,
  walletAddressBookModalOpen: false,
  walletAddressAddModalOpen: false,
  editableFiatAddress: {} as FavoriteFiatDestination,
};
