import { lazyMinLoadTime } from './fallback/LazyFallback';

export default {
  NovaMarketingHome: lazyMinLoadTime(() => import('./views/marketing/home/MarketingHome'), 1100),
  NovaTradeExchange: lazyMinLoadTime(() => import('./views/trade/exchange/NovaTradeExchange'), 1100),
  NovaTradeMarkets: lazyMinLoadTime(() => import('./views/trade/markets/NovaMarkets'), 1100),
  NovaTradeQuickBuy: lazyMinLoadTime(() => import('./views/trade/quick-buy/NovaQuickBuy'), 1100),
  NovaTradeWallets: lazyMinLoadTime(() => import('./views/trade/wallets/NovaWallets'), 1100),
  NovaTradeSettings: lazyMinLoadTime(() => import('./views/trade/settings/NovaSettings'), 600),
  NovaHome: lazyMinLoadTime(() => import('./views/trade/home/NovaHome'), 600),
  NovaVaults: lazyMinLoadTime(() => import('./vaults/NovaVaults'), 600),
  NovaLogin: lazyMinLoadTime(() => import('./views/marketing/login/NovaLogin'), 600),
  NovaFaq: lazyMinLoadTime(() => import('./views/marketing/faq/NovaQuestions'), 600),
  NovaLogout: lazyMinLoadTime(() => import('./views/marketing/logout/NovaLogout'), 0),
  Nova404: lazyMinLoadTime(() => import('./views/marketing/404/Nova404'), 0),
  NovaCryptoPay: lazyMinLoadTime(() => import('./views/trade/crypto-pay/NovaCryptoPay'), 600),
  PrivacyPolicy: lazyMinLoadTime(() => import('./views/marketing/login/agreements/PrivacyPolicy'), 600),
  RiskPolicy: lazyMinLoadTime(() => import('./views/marketing/login/agreements/RiskPolicy'), 600),
  Terms: lazyMinLoadTime(() => import('./views/marketing/login/agreements/Terms'), 600),
};
